.form {
  width: 100%;
  height: 100%;
  border: solid 1px #59a1ca;
  border-radius: 16px;
  padding: 16px;
}

.zipcodeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.dateContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 50%;
  margin-right: 16px;
}

.hourContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 25%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

@media (max-width: 720px) {
  .form {
    height: auto;
    border: none;
  }
  .buttonContainer {
    margin-top: 16px;
  }
  .hourContainer {
    margin-left: 0px;
  }
  .dateContainer {
    width: 70%;
  }
}
