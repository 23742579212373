.bookingLabelContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0px 16px;
}

.bookingLabel {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.bookingDot {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.selected {
  background-color: #80dc56;
}

.available {
  background-color: #fff;
  border: solid 1px #307df6;
}

.occupied {
  background-color: #dbdbdb;
}

.slotsContainer {
  padding: 8px 0px 8px 16px;
  height: 400px;
}

.slotContainer {
  position: relative;
  padding: 12px 16px 12px 24px;
}

.slot {
  padding: 12px 24px;
  border-radius: 24px;
}

.slotDot {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #fff;
  border: solid 1px #307df6;
  left: -5px;
  top: 30px;
  text-align: center;
}

.slotTopLine {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  background-color: #000;
  height: 26px;
}
.slotBottomLine {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  background-color: #000;
  height: 26px;
}

@media (max-width: 720px) {
  .slotsContainer {
    height: 300px;
  }
}
