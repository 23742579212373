.progress-bar {
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-color: #c5c6d0;
}
.progress-fill {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #005ac3;
  border-radius: 6px;
  height: 12px;
}
.progress-dot {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #ffffff;
}
