.rbc-toolbar button.rbc-start {
  border-radius: 12px;
}

.rbc-toolbar button.rbc-end {
  border-radius: 12px;
}
.rbc-header {
  text-align: start;
  font-size: "100%";
}
.rbc-label {
  color: var(--gray-500, #71717a);
  text-align: right;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.rbc-timeslot-group {
  min-height: 120px !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100% !important;
}

.rbc-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}

.rbc-background-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}
