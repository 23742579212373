* {
  margin: 0;
  padding: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-select {
  user-select: none;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
