.container {
  margin: 24px 0px;
  background-color: #fff;
  box-shadow: 0px 8px 0px 0px #a3c9de;
  margin-bottom: 36px;
  border-radius: 24px;
}
.detailedContainer {
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 24px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.buttonContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

@media (max-width: 720px) {
  .buttonContainer {
    right: 0;
    display: flex;
    justify-content: center;
  }
}
