.fixed-size::-webkit-scrollbar,
.fixed-size::-webkit-scrollbar-thumb,
.fixed-size::-webkit-scrollbar-track {
  margin: 2px;
  width: 8px;
  border: none;
  background: transparent;
}

.fixed-size::-webkit-scrollbar-button,
.fixed-size::-webkit-scrollbar-track-piece,
.fixed-size::-webkit-scrollbar-corner,
.fixed-size::-webkit-resizer {
  display: none;
}

.fixed-size::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a3c9de;
}

.fixed-size::-webkit-scrollbar-track {
  background: #a3c9de;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background-clip: padding-box;
}
