.calendarContainer {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 24px;
}

.alertContainer {
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  position: absolute;
  top: 12px;
  right: 12px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
