.cardContent {
  display: flex;
  padding: 16px;
  min-width: 300px;
}

.ratingContainer {
  display: flex;
  align-items: center;
}
