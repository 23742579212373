.header {
  display: flex;
  flex-direction: column;
  margin-top: 12px 0px 12px 0px;
}

.profile-header {
  display: flex;
  position: relative;
}

.profile-header-fs {
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile-primary-details {
  padding-left: 24px;
}

.profile-rating-container {
  display: flex;
  align-items: center;
  padding: 12px;
  padding-bottom: 24px;
}

.profile-rating-container-fs {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
}

.title-width {
  min-width: 100px;
}

.capsules-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.capsule {
  border: solid 1px #cccccc;
  padding: 4px 16px;
  border-radius: 24px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.edit-profile-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px #0000004d;
  box-shadow: 0px 4px 8px 3px #00000026;
}
