.container {
  position: relative;
  display: flex;
}

.search-container {
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  max-height: 160px;
  padding: 4px 0;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.search-item {
  padding: 8px 10px;
  cursor: pointer;
  user-select: none;
}

.search-item:hover {
  background-color: #f5f5f5;
}
